import { ArrowLeftIcon } from "@heroicons/react/solid";
import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import usePut from "../../hooks/usePut";
import Moment from "react-moment";
import { config, TOKEN } from "../../config";
import Cookies from "universal-cookie";
import axios from "axios";
import { useIntersectionObserver } from "usehooks-ts";
const processing_status = {
  1: {
    color: "bg-green-200 text-green-600",
    value: "Attendance Processed",
  },
  2: {
    color: "bg-yellow-200 text-yellow-600",
    value: "Attendance Processing",
  },
  3: {
    color: "bg-red-200 text-red-600",
    value: "Attendance Not Processed",
  },
};
export const getActivityLog = async (id, page = 0, limit ="") => {
  const cookies = new Cookies();
  const url = `${config.apiurl}activity_log_listing`; // Assuming config.apiurl holds your base API URL
  let token = cookies.get(TOKEN);
  let fd = new FormData();

  let formData = new FormData();
  formData.append("page_number", page);
  formData.append("limit", limit);
  formData.append("screen", "ATTENDANCE");
  formData.append("id", Number(id));
  formData.append("log_type", ""); // Add your log_type values as needed
  try {
    const response = await axios({
      method: "POST",
      url: `${config.apiurl}activity_log_listing`,
      data: formData,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "multipart/form-data", // Set Content-Type for FormData
      },
    });

    return response.data; // Assuming you want to return the data from the response
  } catch (error) {
    console.error("Error fetching activity logs:", error);
    throw error; // Rethrow the error for handling in the calling component
  }
};

const ViewAttendanceDetail = ({ open, onClose, id }) => {
  const [attendanceData, setAttendanceData] = useState({});
  const [activityLogData, setActivityLogData] = useState({});
  // const [showDetails, setShowDetails] = useState(false);
  const [showDetails, setShowDetails] = useState(
    activityLogData.data
      ? new Array(activityLogData.data.length).fill(false)
      : []
  );
  useEffect(() => {
    setShowDetails(
      activityLogData.data
        ? new Array(activityLogData.data.length).fill(false)
        : []
    );
  }, [activityLogData]);
  // const toggleDetails = () => {
  //   setShowDetails(!showDetails);
  // };
  const toggleDetails = (index) => {
    setShowDetails((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const { handleAuth } = usePut({
    url: "viewAttendance",
    onSuccess: (res) => {
      setAttendanceData(res.data.data);
    },
    onError: () => {},
  });
  //   useEffect(() => {
  //     if (open) {
  //       let data = {
  //         id,
  //       };
  //       handleAuth.mutate(data);
  //     }
  //   }, [open]);
  useEffect(() => {
    if (open) {
      let data = {
        id,
      };
      handleAuth.mutate(data);

      getActivityLog(id)
        .then((activityData) => setActivityLogData(activityData))
        .catch((error) => console.error(error));
    }
  }, [open]);
  //
  //
  //
  //
  //
  const statusLabels = {
    CREATE: "Created by",
    UPDATE: "Updated by",
    DELETE: "Deleted by",
    START_PROCESSING: "Processing started by",
    END_PROCESSING: "Processing ended  by",
  };
  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: (isIntersecting) => {
      console.log("ffff");
      
      // if (
      //   isIntersecting &&
      //   !logRes?.isFetching &&
      //   totalCount > activityLogs.length
      // ) {
      //   setRequestObject((prev) => ({
      //     ...prev,
      //     pageNo: currentPage + 1,
      //   }));
      // }
    },
  });
  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <div
        className="h-full bg-white px-8"
        style={{ width: "calc(100vw - 550px)" }}
      >
        <div className="flex items-center justify-between  py-5 border-b border-gray-200 sticky z-40 top-0 bg-white">
          <div className="flex items-center space-x-3">
            <ArrowLeftIcon
              className=" text-gray-700 w-8 h-8 cursor-pointer"
              onClick={onClose}
            />
            <p className="text-gray-700 text-3xl font-semibold">Attendance</p>
          </div>
          <div>
            {/* <span className=' bg-green-200 text-green-600 px-4 py-1 font-semibold rounded-md'>Attendance Processed</span> */}
            <span
              className={` px-4 py-1 font-semibold rounded-md ${
                processing_status[attendanceData?.processing_status]?.color
              }`}
            >
              {processing_status[attendanceData?.processing_status]?.value}
            </span>
          </div>
        </div>
        <form method="post " className="divide-y">
          <div className="grid grid-cols-2 gap-4 py-4">
            <div className="">
              <label htmlFor="holiday_name" className="label">
                In-Date:<span className="mandatory">*</span>
              </label>
              <label className=" label input">
                <Moment unix date={attendanceData?.in_date} format="L" />
              </label>
            </div>
            <div className="">
              <label htmlFor="holiday_name" className="label">
                Out-Date:<span className="mandatory">*</span>
              </label>
              <label className=" label input">
                <Moment unix date={attendanceData?.out_date} format="L" />
              </label>
            </div>
            <div className="">
              <label htmlFor="in_time" className="label">
                In Time:<span className="mandatory">*</span>
              </label>
              <input
                disabled
                type="time"
                name="in_time"
                value={attendanceData?.in_time}
                className="input"
              />
            </div>
            <div className="">
              <label htmlFor="out_time" className="label">
                Out Time:<span className="mandatory">*</span>
              </label>
              <input
                disabled
                type="time"
                name="out_time"
                value={attendanceData?.out_time}
                className="input"
              />
            </div>
            <div className="">
              <div>
                <label htmlFor="out_time" className="label">
                  Pre-Shift OT
                </label>
                <p className="input">{attendanceData?.pre_shift_ot} min</p>
              </div>
              <div className="mt-2">
                <label className=" space-x-2">
                  <input
                    disabled
                    type="checkbox"
                    name=""
                    id=""
                    checked={
                      attendanceData?.approval_status_pre_shift_ot === "1"
                    }
                  />
                  <span>Approve Pre-Shift OT</span>
                </label>
              </div>
            </div>
            <div className="">
              <div>
                <label htmlFor="out_time" className="label">
                  Post-Shift OT
                </label>
                <p className="input">{attendanceData?.post_shift_ot} min</p>
              </div>
              <div className="mt-2">
                <label className=" space-x-2">
                  <input
                    disabled
                    type="checkbox"
                    checked={
                      attendanceData?.approval_status_post_shift_ot === "1"
                    }
                  />
                  <span>Approve Post-Shift OT</span>
                </label>
              </div>
            </div>
            <div className="">
              <label htmlFor="status" className="label">
                Status:<span className="mandatory">*</span>
              </label>
              <select
                disabled
                className="input"
                name="status"
                value={attendanceData?.present_status}
              >
                <option value="">--Select Attendance--</option>
                <option value="P">Present</option>
                <option value="A">Absent</option>
              </select>
            </div>
            <div className="">
              <label htmlFor="status" className="label">
                Present Definition:
              </label>
              <p className="input">{attendanceData?.present_definition}</p>
            </div>
            <div className="">
              <label htmlFor="status" className="label">
                Payment Definition:
              </label>
              <p className="input">{attendanceData?.payment_definition}</p>
            </div>
            <div className="">
              <div>
                <label htmlFor="out_time" className="label">
                  Late Duration
                </label>
                <p className="input">{attendanceData?.late_duration}</p>
              </div>
              <div className="mt-2">
                <label className=" space-x-2">
                  <input
                    disabled
                    type="checkbox"
                    name=""
                    id=""
                    checked={attendanceData?.late === "1"}
                  />
                  <span>Mark Late</span>
                </label>
              </div>
            </div>
            <div className="">
              <div className="grid grid-cols-2 gap-x-3">
                <div>
                  <label htmlFor="out_time" className="label">
                    Total Working Hours
                  </label>
                  {
                    <p className="input">
                      {attendanceData?.total_worked_hours}
                    </p>
                  }
                </div>
                <div>
                  <label htmlFor="out_time" className="label">
                    Variable OT
                  </label>
                  <p className="input">{attendanceData?.variable_ot}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-3">
                <div>
                  <label htmlFor="shift_working_hours" className="label">
                    Shift Working Hours
                  </label>
                  {
                    <p className="input">
                      {attendanceData?.shift_working_hours}
                    </p>
                  }
                </div>
                <div>
                  <label htmlFor="remarks" className="label">
                    Remarks
                  </label>
                  <p className="input">{attendanceData?.remarks}</p>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* <table className="table-auto w-full"> */}
        {/* <thead>
            
            <tr className=" bg-[color:var(--color1)] text-center">
              <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                Sr.No
              </th>
              <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                Name
              </th>
              <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                Date
              </th>
              <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                Type
              </th>
              <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                Status
              </th>
              <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                Process started on
              </th>
              <th className="text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                Process completed on
              </th>
            </tr>
          </thead> */}

        {/* <tbody>
  {activityLogData.data && activityLogData.data.length > 0 ? (
    activityLogData.data.map((log, idx) => (
      <tr key={idx}>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{log.id}</td>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{log.name}</td>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{log.log_date}</td>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{log.log_type}</td>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{log.log_status}</td>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{log.process_started_on}</td>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{log.process_completed_on}</td>
        <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
         
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={8}>
        <div className="text-center py-5 text-gray-700 text-3xl font-semibold">Oops!!! No Data found</div>
      </td>
    </tr>
  )}



          </tbody>
        </table> */}
        <div>
          <p className="pt-2 font-semibold text-xl">Activity Logs</p>
        </div>
        <div className="text-start max-h-[300px] overflow-auto text-sm divide-y gap-2 flex flex-col pb-5">
          {activityLogData.data && activityLogData.data.length > 0 ? (
            activityLogData.data.map((log, idx) => (
              <div
                key={idx}
                className="text-sm gap-1  items-start flex text-dark flex-col"
              >
                <div className="flex items-center">
                  <p>
                    <span>{statusLabels[log.log_status]}</span>{" "}
                    {log.name ? log.name : "System"}, at {log.log_date}
                  </p>
                  {log.log_status === "UPDATE" && (
                    <button
                      className="ml-2 underline outline-none px-2 text-[color:var(--color1)] text-white py-1 rounded-md text-sm"
                      onClick={() => toggleDetails(idx)}
                    >
                      {showDetails[idx] ? "Hide Log Detail" : "View Log Detail"}
                    </button>
                  )}
                </div>
                {showDetails[idx] && (
          <div className="flex flex-col mt-2">
            {log?.log_data?.length > 0 ? (
              <div>
                {log?.log_data?.map((logData, logDataIdx) => {
                  return <div key={logDataIdx}>
                  <p>{logData.payment_definition?.previous_data}</p>
                  <p>{logData.payment_definition?.change_data}</p>
                  </div> 
                })}
              </div>
            ) : (
              <p>No additional details available.</p>
            )}
         
              </div>
            )}
              </div>
            ))
          ) : (
            <p className="text-gray-700 text-3xl text-center font-semibold">
              Oops!!! No Data found
            </p>
          )}
        </div>
        {!activityLogData?.isFetching && <div ref={ref} style={{ height: "1px" }}></div>}
      </div>
    </Drawer>
  );
};

export default ViewAttendanceDetail;



// {/* Check if log_data is an object and render it appropriately */}
// {typeof log.log_data === "object" && log.log_data !== null ? (
//   // If it's an object, stringify it for display
//   <p>{log.log_data}</p>
// ) : (
//   // If it's not an object, just render the text
//   <p>{log.log_data}</p>
// )}
